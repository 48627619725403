// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';

import ODBC_EDIT_PAGE from 'queries/OdbcEditPage.graphql';

export const odbcEditRoute = {
    path: ':odbcId/edit',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcEditPage" */
        'components/pages/OdbcEditPage'
    ),
    loader: async ({ params }) => {
        const id = btoa(`OdbcDsnConnection:${params.odbcId}`);

        await apolloClient.query({
            query: ODBC_EDIT_PAGE,
            variables: { id },
        });

        return { odbcId: params.odbcId };
    },
};
