// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

export const toFormData = (values: Record<string, unknown> | FormData): FormData => {
    if (values instanceof FormData) {
        return values;
    }

    const consume = (object: object, namespace?: string) => {
        Object.entries(object).forEach(([k, value]) => {
            const name = namespace ? `${namespace}[${k}]` : k;
            switch (true) {
                case value && typeof value === 'object':
                    consume(value, name);
                    break;

                case value == null:
                    data.append(name, '');
                    break;

                default:
                    data.append(name, value);
                    break;
            }
        });
    };

    const data = new FormData();
    consume(values);
    return data;
};
