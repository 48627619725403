// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { PopupForm } from './popup-form';
import { Tree } from './file-manager/tree';
import emptyFn from './emptyFn';

export class DirectoryBrowser extends PopupForm {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._pathElementId = this._getConfigParam('pathElementId');
        this._subscriptionId = this._getConfigParam('subscriptionId');
        this._showFiles = this._getConfigParam('showFiles', false);
        this._onSubmit = this._getConfigParam('onSubmit', emptyFn);
    }

    render() {
        super.render();

        this.setBoxType('form-box');
        this.setTitle(this.lmsg('title'));

        document.getElementById(this._contentAreaId).innerHTML = (
            `<form method="post" id="${this._id}-form">` +
                '<div class="single-row">' +
                    '<div class="scrollable fm-scrollable" style="height: 300px;">' +
                        '<div class="scrollable-wrap">' +
                            `<div id="${this._id}-files-tree" class="tree"></div>` +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</form>'
        );

        this._tree = new Tree({
            applyTo: `${this._id}-files-tree`,
            data: null,
            dataUrl: `^/smb/file-manager/tree-data/subscriptionId/${this._subscriptionId}`,
            showFiles: this._showFiles,
            onNodeClick: this._onTreeNodeClick.bind(this),
            onReload() {
                this.setDirectory('/');
            },
            ...this._getConfigParam('treeConfig', {}),
        });

        this._buttonOk = this.addRightButton(this.lmsg('buttonOk'), this._onOkClick, true, true);
        this._buttonCancel = this.addRightButton(this.lmsg('buttonCancel'), this._onCancelClick, false, false);

        if (this._showFiles) {
            this._updateButton(this._buttonOk, { disabled: true });
        }
    }

    _onTreeNodeClick() {
        this._updateButton(this._buttonOk, { disabled: this._showFiles && this._tree.getSelectedItemData().isDirectory });
    }

    _onOkClick(event) {
        if (event) {
            event.preventDefault();
        }

        const selectedPath = this._tree.getSelectedItemData().path;
        const pathValue = this._showFiles ? selectedPath.substr(1) : selectedPath;
        if (this._pathElementId) {
            document.getElementById(this._pathElementId).value = pathValue;
        }
        this._onSubmit(pathValue);
        this.hide();
    }

    _onCancelClick() {
        this.hide();
    }
}
