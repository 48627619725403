// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import pageData from './pageData';

export const getContextUrl = (domainId: string | number, url: string): string => {
    const { pageSidebar } = pageData();
    const returnUrl = encodeURIComponent(url);
    return pageSidebar?.isPowerUserPanel
        ? `/smb/account/switch/id/${domainId}?hideNotice=1&returnUrl=${returnUrl}`
        : `/admin/subscription/login/id/${domainId}?pageUrl=${returnUrl}`;
};
