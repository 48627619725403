// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { List, ListProps, Pagination } from '@plesk/ui-library';
import { GraphQlListContext, GraphQlListContextType } from './GraphQlListContext';
import GraphQlListSearchBar from './GraphQlListSearchBar';

type GraphQlListProps <ListData extends Record<string, unknown>> = {
    emptyViewFiltered?: ListProps<ListData>['emptyView'];
    listContext: GraphQlListContextType;
} & ListProps<ListData>;

const GraphQlList = <ListData extends Record<string, unknown>>({
    onSortChange,
    filtered,
    pagination,
    emptyView,
    emptyViewFiltered = undefined,
    listContext,
    ...listProps
}: GraphQlListProps<ListData>) => {
    const { paginationProps, setSort } = listContext;

    const [prevFiltered, setPrevFiltered] = useState(filtered);
    const isLoadingOrFiltered = filtered || (listProps.loading && prevFiltered);
    if (!listProps.loading && prevFiltered !== filtered) {
        setPrevFiltered(filtered);
    }

    return (
        <GraphQlListContext.Provider value={listContext}>
            <List
                filtered={isLoadingOrFiltered}
                pagination={pagination || (paginationProps && <Pagination {...paginationProps} />)}
                emptyView={isLoadingOrFiltered ? emptyViewFiltered : emptyView}
                {...listProps}
                onSortChange={(...args) => {
                    onSortChange?.(...args);
                    const [sort] = args;
                    setSort({ [sort.sortColumn]: sort.sortDirection });
                }}
            />
        </GraphQlListContext.Provider>
    );
};

GraphQlList.SearchBar = GraphQlListSearchBar;

export default GraphQlList;
