// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { makeVar, useReactiveVar } from '@apollo/client';
import { ToasterProps } from '@plesk/ui-library';

type Toast = Omit<Exclude<ToasterProps['toasts'], undefined>[number], 'key'>;

type ToastWithKey = Toast & { key: number };

const toasts = makeVar<ToastWithKey[]>([]);
let key = 0;

export const useToasts = () => useReactiveVar(toasts);

export const addToast = ({ intent, ...props }: Toast) => {
    toasts([
        ...toasts(),
        {
            key: key++,
            intent,
            autoClosable: intent && ['success', 'info'].includes(intent) ? true : undefined,
            ...props,
        },
    ]);
};

export const removeToast = ({ key }: { key: number }) => toasts(toasts().filter(toast => toast.key !== key));

export default toasts;
