// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Component } from './component';
import prepareUrl from './prepareUrl';
import render from './render';
import createElement from './createElement';
import api from './api';

import './status-message.less';

export class StatusMessage extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._type = this._getConfigParam('type', 'error');
        this._cls = this._getConfigParam('cls', `msg-box msg-${this._type}`);
        this._title = this._getConfigParam('title', '');
        this._message = this._getConfigParam('message', '');
        this._source = this._getConfigParam('source', null);
        this._closable = this._getConfigParam('closable', false);
        this._onClose = this._getConfigParam('onClose', null);
        const hideUrl = this._getConfigParam('hideUrl', null);
        if (hideUrl) {
            this._closable = true;
            this._onClose = event => {
                const el = event.target.closest('.msg-box');
                el.parentNode.removeChild(el);
                api.post(prepareUrl(hideUrl));
            };
        }
    }

    _initComponentElement() {
        super._initComponentElement();
        if (this._source) {
            this._componentElement.dataset.source = this._source;
        }

        render(this._componentElement,
            createElement('div',
                createElement('div',
                    createElement('div',
                        createElement('div',
                            createElement('div',
                                createElement('div.msg-content',
                                    this._renderCloseButton(),
                                    this._renderMessage(),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        );
    }

    _renderMessage() {
        return [this._title ? `<b>${this._title}:</b> ` : null, this._message];
    }

    _renderCloseButton() {
        if (!this._closable) {
            return null;
        }
        return createElement('span.close', {
            onclick: typeof this._onClose === 'function'
                ? this._onClose.bind(this)
                : this._onClose,
        });
    }
}
