// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { isValidElement } from 'react';
import { Component } from './component';

/**
 * Helper for create elements
 * @experimental Be careful with this method, its implementation is not yet complete.
 *
 * @param {String} selector
 * @param {Object} [attrs]
 * @param {String[]|String...|Object[]|Object...} [children]
 * @returns {Object}
 */
export default (selector, ...args) => {
    const hasAttrs = Object.prototype.toString.call(args[0]) === '[object Object]'
        && !('tag' in args[0])
        && !isValidElement(args[0])
        && !(args[0] instanceof Component);
    let tag = 'div';
    const classes = [];
    const selectorRe = /(?:(^|#|\.)([^#\.\[\]]+))|(\[.+?\])/g;
    const attrRe = /\[(.+?)(?:=("|'|)(.*?)\2)?\]/;
    let match;
    const attrs = hasAttrs ? args.shift() : {};

    while ((match = selectorRe.exec(selector))) {
        if (match[1] === '' && match[2]) {
            tag = match[2];
        } else if (match[1] === '#') {
            attrs.id = match[2];
        } else if (match[1] === '.') {
            classes.push(match[2]);
        } else if (match[3][0] === '[') {
            const pair = attrRe.exec(match[3]);
            attrs[pair[1]] = pair[3] || (pair[2] ? '' : true);
        }
    }

    if (typeof attrs.class === 'string' && attrs.class !== '') {
        classes.push(attrs.class);
    }
    if (classes.length) {
        attrs.class = classes.join(' ');
    }

    const children = args.length === 1 && Array.isArray(args[0]) ? args[0] : args;

    return {
        tag,
        attrs,
        children,
    };
};
