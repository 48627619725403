// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import apolloClient from 'apolloClient';

import PROTECTED_DIRECTORY_PATH from 'queries/ProtectedDirectoryPath.graphql';

export const protectedDirectoryUserAddRoute = {
    path: 'add',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryUserAddPage" */
        'components/pages/ProtectedDirectoryUserAddPage'
    ),
    loader: async ({ params }) => {
        const { data } = await apolloClient.query({
            query: PROTECTED_DIRECTORY_PATH,
            variables: { id: btoa(`ProtectedDirectory:${params.directoryId}`) },
        });

        return data;
    },
};
