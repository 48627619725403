// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from '../apolloClient';
import { PasswordEstimation } from 'schema';

import PASSWORD_ESTIMATION_QUERY from 'queries/PasswordEstimation.graphql';

const defaultEstimateFn = async (password: string) => {
    const { data: { passwordEstimation } } = await apolloClient.query({
        query: PASSWORD_ESTIMATION_QUERY,
        variables: { password },
    });
    return passwordEstimation;
};

type PasswordStrength = {
    password: string;
    strength: string;
    suggestions: string[];
}

export default async (
    password: string,
    estimateFn: (password: string) => Promise<PasswordEstimation> = defaultEstimateFn,
): Promise<PasswordStrength> => {
    const [{ level, suggestions }, locale] = await Promise.all([
        estimateFn(password),
        Locale.loadSection('components.password.suggestions'),
    ]);

    return {
        password,
        strength: ['VeryWeak', 'Weak', 'Medium', 'Strong'][level] ?? 'VeryStrong',
        suggestions: suggestions.map(suggestion => locale.lmsg(suggestion)),
    };
};
