// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useCallback, useState } from 'react';
import { Pagination } from '@plesk/ui-library';
import useListOperation from './useListOperation';

export const ALL_ITEMS = 'all';
export const ITEM_PER_PAGE_OPTIONS_DEFAULT = [10, 25, 100, ALL_ITEMS];
export const ALL_ITEMS_COUNT = 100_000;

/**
 * hook for add sort and pagination for the ui-library list component
 */
const useList = ({
    onReload,
    onFilter,
    initialFilter,
    pagination: {
        current: currentPage,
        totalPage: totalPages,
        itemsPerPage,
        itemsPerPageOptions,
        onPageChange,
        onChangeItemsPerPage,
    } = {},
    sort: {
        direction: sortDirection,
        column: sortColumn,
        onChange: onSortChange,
    } = {},
}) => {
    const { selection, setSelection, loadingRows, wrapListOperation } = useListOperation();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(initialFilter);

    const wrapAsync = async operation => {
        setLoading(true);
        await operation();
        setLoading(false);
    };

    const renderPagination = useCallback(() => (
        <Pagination
            current={currentPage}
            total={totalPages}
            itemsPerPage={itemsPerPage}
            onSelect={nextPage => wrapAsync(() => onPageChange(nextPage))}
            onItemsPerPageChange={itemsPerPage => wrapAsync(() => {
                const nextItemsPerPage = itemsPerPage === ALL_ITEMS ? ALL_ITEMS_COUNT : itemsPerPage;
                onChangeItemsPerPage(nextItemsPerPage);
            })}
            itemsPerPageOptions={itemsPerPageOptions ?? ITEM_PER_PAGE_OPTIONS_DEFAULT}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [currentPage, itemsPerPage, itemsPerPageOptions, totalPages]);

    const sortParams = {
        sortColumn,
        sortDirection,
        onSortChange: (...params) => wrapAsync(() => onSortChange(...params)),
    };

    return {
        renderPagination,
        selection,
        setSelection,
        loading,
        loadingRows,
        filter,
        sortParams,
        wrapListOperation,
        handleReload: params => wrapAsync(() => onReload(params)),
        handleFilter: params => wrapAsync(async () => {
            await onFilter(params);
            setFilter(params);
        }),
    };
};

export default useList;
