// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import MainHeaderSearch from './MainHeaderSearch';
import MainHeaderNavbar from './MainHeaderNavbar';

const PageHeader = ({ searchUrl = null, ...props }) => (
    <div className="page-header">
        {searchUrl ? (
            <MainHeaderSearch url={searchUrl} />
        ) : null}
        <MainHeaderNavbar {...props} />
    </div>
);

PageHeader.propTypes = {
    searchUrl: PropTypes.string,
};

export default PageHeader;
