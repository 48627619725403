// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { getStartOfDay } from 'helpers/dates';
import { getSubjectIntent } from './notificationIntent';

export default (notifications, includeParentAsChild = true, includeRead = true) => {
    const groupedNotifications = {};
    notifications.forEach(notification => {
        if (!includeRead && notification.isRead) {
            return;
        }
        const day = getStartOfDay(notification.displayDate).getTime();
        const key = `${day}:${notification.context}${notification.category}:${notification.subject}`;
        if (!groupedNotifications[key]) {
            groupedNotifications[key] = { ...notification };
        } else {
            if (!groupedNotifications[key].notifications) {
                const originalNotification = { ...groupedNotifications[key] };
                groupedNotifications[key].notifications = includeParentAsChild ? [originalNotification] : [];
                groupedNotifications[key].intent = getSubjectIntent(originalNotification);
            }
            groupedNotifications[key].notifications.push(notification);
            groupedNotifications[key].isFavorite = groupedNotifications[key].isFavorite && notification.isFavorite;
            groupedNotifications[key].isRead = groupedNotifications[key].isRead && notification.isRead;
        }
    });
    return Object.values(groupedNotifications);
};
