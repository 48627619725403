// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { protectedDirectoryListRoute } from 'routes/protectedDirectoryListRoute';
import { protectedDirectoryUserListRoute } from 'routes/protectedDirectoryUserListRoute';

export const protectedDirectoriesRoute = {
    path: 'protected-directories',
    handle: {
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('protectedDirectories'),
        }),
        returnUrl: ({ params }) => `/cp/domains/${params.domainId}/protected-directories`,
    },
    children: [
        protectedDirectoryListRoute,
        protectedDirectoryUserListRoute,
    ],
};
