// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { readGraphQlListInputFromStorage } from '../components/common/GraphQlList';
import { Locale } from 'jsw';

export const resourceUsageByDomainRoute = ({ path = 'resource-usage', ignoreTopLevelTitle = false } = {}) => ({
    path,
    componentLoader: () => import(/* webpackChunkName: "components/pages/DomainsResourceUsagePage" */'components/pages/DomainsResourceUsagePage'),
    loader: async () => {
        const res = await apolloClient.query({
            query: require('queries/DomainsResourceUsage.graphql'),
            variables: {
                input: readGraphQlListInputFromStorage({ key: 'ResourceUsagePage' }),
            },
        });

        return res;
    },
    handle: {
        crumb: () => ignoreTopLevelTitle ? [] : ({
            title: Locale.getSection('components.routes').lmsg('resourceUsageByDomains'),
        }),
        helpContext: 'client.domain-summary-report',
    },
});
