// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { trafficUsageByDomainRoute } from 'routes/trafficUsageByDomainRoute';
import TRAFFIC_USAGE_INTERVALS from 'queries/TrafficUsageIntervals.graphql';
import TRAFFIC_USAGE_BY_VIEWER_DOMAINS_LIST from 'queries/TrafficUsageByViewerDomainsList.graphql';
import TOTAL_TRAFFIC_USAGE_BY_VIEWER_DOMAINS from 'queries/TotalTrafficUsageByViewerDomains.graphql';


export const trafficUsageByViewerDomainsChildrenRoute = ({ path = 'traffic-usage/domains' } = {}) => ({
    path,
    handle: {
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('trafficUsageByDomains'),
        }),
    },
    children: [
        trafficUsageByDomainRoute({ path: ':domainId' }),
    ],
});

export const trafficUsageByViewerDomainsRoute = ({ path = 'traffic-usage/domains', ignoreTopLevelTitle = false } = {}) => ({
    path,
    handle: {
        crumb: () => ignoreTopLevelTitle ? [] : ({
            title: Locale.getSection('components.routes').lmsg('trafficUsageByDomains'),
        }),
        helpContext: 'client.domain.traffic',
    },
    children: [
        {
            index: true,
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/TrafficUsageByDomainsPage" */
                'components/pages/TrafficUsageByDomainsPage'
            ),
            loader: async () => {
                const trafficUsageListKey = 'TrafficUsageByViewerDomainsList';

                const [{ data: intervalsData }] = await Promise.all([
                    apolloClient.query({
                        query: TRAFFIC_USAGE_INTERVALS,
                    }),
                    apolloClient.query({
                        query: TOTAL_TRAFFIC_USAGE_BY_VIEWER_DOMAINS,
                    }),
                    apolloClient.query({
                        query: TRAFFIC_USAGE_BY_VIEWER_DOMAINS_LIST,
                        variables: {
                            input: readGraphQlListInputFromStorage({ key: trafficUsageListKey }),
                        },
                    }),
                ]);

                const { trafficUsageIntervals } = intervalsData.viewer.client.statistics;

                return {
                    trafficUsageIntervals,
                    listQueryParams: {
                        key: trafficUsageListKey,
                        query: TRAFFIC_USAGE_BY_VIEWER_DOMAINS_LIST,
                        extractList: data => data.viewer.client.statistics.trafficUsageByDomains,
                    },
                    totalUsageQueryParams: {
                        query: TOTAL_TRAFFIC_USAGE_BY_VIEWER_DOMAINS,
                        extractTrafficData: data => data.viewer.client.statistics.totalTrafficUsageByDomains,
                    },
                };
            },
        },
    ],
});
