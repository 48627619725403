// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useCallback, useState } from 'react';
import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';

type SetAction<V> = (value: V) => void;

interface UseLocalStorage {
    <V>(key: string, initialValue: V): [V, SetAction<V>];
    <V = undefined>(key: string): [V | undefined, SetAction<V>];
}

export const useLocalStorage: UseLocalStorage = function <V> (
    key: string,
    initialValue?: V | undefined,
) {
    const [storedValue, setStoredValue] = useState<V | undefined>(
        () => readLocalStorageValue(key, initialValue),
    );

    const setValue = useCallback((value: V) => {
        setStoredValue(value);

        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key]);

    return [storedValue, setValue];
};

export default useLocalStorage;

