// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';

export const CtaPropType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
});

export const NOTIFICATION_INTENTS = {
    DANGER: 'danger',
    WARNING: 'warning',
    INFO: 'info',
    INACTIVE: 'inactive',
};

export const NOTIFICATION_CATEGORIES = {
    INFORMATION: 'Information',
    WARNING: 'Warning',
    ALERT: 'Alert',
    PROMOTION: 'Promotion',
    FEEDBACK: 'Feedback',
};

export const NotificationPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    isRead: PropTypes.bool.isRequired,
    isViewed: PropTypes.bool.isRequired,
    isFavorite: PropTypes.bool.isRequired,
    category: PropTypes.oneOf(Object.values(NOTIFICATION_CATEGORIES)).isRequired,
    context: PropTypes.string.isRequired,
    contextIconUrl: PropTypes.string,
    area: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    displayDate: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    actionLink: PropTypes.string,
    actionText: PropTypes.string,
    openInNewTab: PropTypes.bool,
    isPromotion: PropTypes.bool,
});

export const NotificationFilterPropType = PropTypes.shape({
    search: PropTypes.string,
    isUnRead: PropTypes.bool,
    isCritical: PropTypes.bool,
    isFavorite: PropTypes.bool,
});
