// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';

const DEFAULT_INPUT = {
    pagination: { page: 1, itemsPerPage: 25 },
};

// Bump version after GraphQL API changes to prevent reading of invalid input from storage
const VERSION = 2;

type Options <ListInput> = {
    key: string;
    withPagination?: boolean;
    defaultInput?: ListInput;
};

export const getGraphQlListInputStorageArgs = <ListInput> ({
    key,
    withPagination = true,
    defaultInput,
}: Options<ListInput>) => [
    `${key}ListInput.v${VERSION}`,
    defaultInput || (withPagination ? DEFAULT_INPUT : {}) as ListInput,
] as const;

export const readGraphQlListInputFromStorage = <ListInput> (
    options: Options<ListInput>,
) => readLocalStorageValue(...getGraphQlListInputStorageArgs(options));
