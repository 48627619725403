// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

export const SERVER_INFO = 'serverInfo';
export const UPDATES = 'updates';
export const OVERUSED_SUBSCRIPTIONS = 'overusedSubscriptions';
export const MY_SUBSCRIPTIONS = 'mySubscriptions';
export const IP_BAN = 'ipBan';
export const BACKUP = 'backup';
export const EXTENSIONS = 'ext';
export const MAIL_QUEUE = 'mailQueue';
export const PROMO = 'promo';
export const CHANGE_LOG = 'changeLog';
export const CUSTOM_BUTTONS = 'customButtons';
