// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { makeVar, useReactiveVar } from '@apollo/client';

type PageData = {
    pageSidebar?: {
        isPowerUserPanel: boolean;
    }
    [key: string]: unknown;
};

const pageData = makeVar<PageData>({});

export const usePageData = () => useReactiveVar(pageData);

export default pageData;
