// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { gql } from '@apollo/client';
import apolloClient from 'apolloClient';
import { trafficUsageByClientDomainsRoute } from 'routes/trafficUsageByClientDomainsRoute';
import { trafficUsageByDomainRoute } from 'routes/trafficUsageByDomainRoute';

export const trafficUsageByCustomerDomainsRoute = path => ({
    path,
    handle: {
        crumb: ({ data }) => ({ title: data.client.name }),
        pageTitle: ({ data }) => Locale.getSection('components.routes').lmsg(
            'trafficUsageByClientDomains',
            { clientName: data.client.name },
        ),
        helpContext: 'client.domain.traffic',
    },
    loader: async ({ params }) => {
        const { customerId } = params;
        const clientNodeId = btoa(`Client:${customerId}`);

        const { data: { client } } = await apolloClient.query({
            query: gql`
                query($clientId: ID!) {
                    client: node(id: $clientId) {
                        ... on Client {
                            id
                            name
                        }
                    }
                }
            `,
            variables: { clientId: clientNodeId },
        });

        return { client };
    },
    children: [
        trafficUsageByClientDomainsRoute({
            clientIdParamName: 'customerId',
            clientType: 'customer',
        }),
        trafficUsageByDomainRoute({ path: ':domainId' }),
    ],
});
