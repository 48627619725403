// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { gql } from '@apollo/client';
import apolloClient from 'apolloClient';

export const protectedDirectoryUserPasswordRoute = {
    path: ':userId/password',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryUserPasswordPage" */
        'components/pages/ProtectedDirectoryUserPasswordPage'
    ),
    loader: async ({ params }) => {
        const { data } = await apolloClient.query({
            query: gql`
                query ($id: ID!) {
                    protectedDirectoryUser: node(id: $id) {
                        ... on ProtectedDirectoryUser { id login }
                    }
                }
            `,
            variables: { id: btoa(`ProtectedDirectoryUser:${params.userId}`) },
        });

        return data;
    },
};
