// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { Alert, Button, Popover, Item, Icon } from '@plesk/ui-library';
import { api, Locale } from 'jsw';
import Link from 'components/common/Link';

const Translate = Locale.getTranslate('components.license-status');

export const STATUS_DEFAULT = 'default';
const STATUS_TEST = 'test';
const STATUS_TRIAL = 'trial';
export const STATUS_EXPIRED = 'expired';

const LicenseStatus = ({
    status,
    daysLeft = 0,
    isAdmin = false,
    actionUrl = null,
    installUrl = null,
    hidePopoverUrl = null,
    trialOverIcon = null,
    baseClassName = 'license-status',
}) => {
    const [trialOverPopoverVisible, setTrialOverPopoverVisible] = useState(daysLeft <= 5 && hidePopoverUrl);

    const handleClosePopover = () => {
        setTrialOverPopoverVisible(false);
        api.post(hidePopoverUrl);
    };

    const renderActions = () => {
        const alertCls = `${baseClassName}-alert`;

        return (actionUrl || installUrl) ? (
            <div className={`${alertCls}__actions`}>
                {actionUrl && (
                    <Button
                        intent="primary"
                        component="a"
                        icon="shopping-cart"
                        className={`${alertCls}__actions-button`}
                        href={actionUrl}
                        target="_blank"
                    >
                        <Translate content={status === STATUS_DEFAULT ? 'getTrialButton' : 'buyLicenseButton'} />
                    </Button>
                )}
                {installUrl && (
                    <Link
                        className={`${alertCls}__actions-link`}
                        to={installUrl}
                    >
                        <Translate content="installLicenseButton" />
                    </Link>
                )}
                {status === 'demo' && (
                    <Translate
                        content="demoLicenseTryPlesk"
                        component="span"
                        className={`${alertCls}__additional-action`}
                        params={{
                            link: (
                                <a
                                    href="https://docs.plesk.com/try-plesk-now/"
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                >
                                    <Translate content="demoLicenseTryPleskLink" />
                                </a>
                            ),
                        }}
                    />
                )}
            </div>
        ) : null;
    };

    const renderTrialPopover = content => {
        const popoverCls = `${baseClassName}-popover`;

        return trialOverPopoverVisible ? (
            <Popover
                className={popoverCls}
                visible
                onClose={handleClosePopover}
                target={content}
            >
                <Item
                    title={<h4><Translate content="trialOverTitle" /></h4>}
                    icon={<Icon size="48" src={trialOverIcon} />}
                >
                    <Translate content="trialOverText" />
                </Item>
            </Popover>
        ) : content;
    };

    const alertCls = `${baseClassName}-alert`;

    let intent = null;
    if (status !== 'demo') {
        intent = daysLeft <= 0 ? 'danger' : (daysLeft <= 5 ? 'warning' : null);
    }

    const content = (
        <div
            className={classNames(baseClassName, { 'license-status--active': trialOverPopoverVisible })}
            data-type="license-status"
        >
            <Alert
                data-type={`license-status-${status}`}
                className={alertCls}
                intent={intent}
            >
                <div className={`${alertCls}__inner`}>
                    <div className={`${alertCls}__message`}>
                        {STATUS_TRIAL === status ? (
                            <div className={`${alertCls}__message-count`}>{daysLeft}</div>
                        ) : null}
                        <div className={`${alertCls}__message-text`}>
                            <Translate content={isAdmin || status === 'demo' ? `${status}LicenseWarningAdmin` : 'testLicenseWarningClient'} />
                        </div>
                    </div>
                    {renderActions()}
                </div>
            </Alert>
        </div>
    );

    return renderTrialPopover(content);
};

LicenseStatus.propTypes = {
    status: PropTypes.oneOf([STATUS_DEFAULT, STATUS_TEST, STATUS_TRIAL, STATUS_EXPIRED]).isRequired,
    daysLeft: PropTypes.number,
    isAdmin: PropTypes.bool,
    actionUrl: PropTypes.string,
    installUrl: PropTypes.string,
    hidePopoverUrl: PropTypes.string,
    trialOverIcon: PropTypes.string,
    baseClassName: PropTypes.string,
};

export default LicenseStatus;
