// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { createContext } from 'react';
import { PaginationProps } from '@plesk/ui-library';

export type Filters = Record<string, string>;

export type SetSearchFilters = (filters: Filters | ((currentFilters?: Filters) => Filters)) => void;
export type SetFilters = (filters: Record<string, unknown>) => void;
export type SetSort = (sort: { [key: string]: 'ASC' | 'DESC' }) => void;
export type SetPage = (page: number) => void;
export type SetItemsPerPage = (itemsPerPage: NonNullable<PaginationProps['itemsPerPage']>) => void;

export type GraphQlListContextType = {
    listData: unknown;
    listInput: {
        searchFilters?: Record<string, string>;
    };
    setSearchFilters: SetSearchFilters;
    setFilters: SetFilters;
    setSort: SetSort;
    setPage: SetPage;
    setItemsPerPage: SetItemsPerPage;
    paginationProps: PaginationProps | undefined;
};

export const GraphQlListContext = createContext<GraphQlListContextType>({} as GraphQlListContextType);
