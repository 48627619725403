// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useContext, useState } from 'react';
import { SearchBar, SearchBarProps } from '@plesk/ui-library';
import { GraphQlListContext } from './GraphQlListContext';

type GraphQlListSearchBarProps = {
    name: string;
} & SearchBarProps;

const GraphQlListSearchBar = ({
    name,
    inputProps = {},
    onSearch,
    onTyping,
    ...props
}: GraphQlListSearchBarProps) => {
    const { listInput, setSearchFilters } = useContext(GraphQlListContext);
    const [value, setValue] = useState(listInput.searchFilters?.[name]);

    return (
        <SearchBar
            inputProps={{
                value,
                'data-testid': 'list-searchbar',
                ...inputProps,
            }}
            {...props}
            onTyping={(...args) => {
                onTyping?.(...args);
                const [value] = args;
                setValue(value);
            }}
            onSearch={(...args) => {
                onSearch?.(...args);
                const [value] = args;
                setValue(value);
                setSearchFilters(currentFilters => ({
                    ...currentFilters,
                    [name]: value,
                }));
            }}
        />
    );
};

export default GraphQlListSearchBar;
