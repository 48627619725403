// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useRef } from 'react';

const useCollector = () => {
    const collectorRef = useRef({
        setCallback(callback) {
            this.callback = callback;
        },
        collect() {
            return this.callback();
        },
    });
    return collectorRef.current;
};

export default useCollector;
