// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isClientSideRedirectAllowed } from 'routes';

export const useServerRouteReload = () => {
    const location = useLocation();
    const initialized = useRef(false);

    useEffect(() => {
        if (initialized.current && !isClientSideRedirectAllowed(location.pathname)) {
            window.location.reload();
        }
        initialized.current = true;
    }, [location.pathname]);
};
