// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

/**
 * Shuffle an array with the Fisher-Yates shuffle algorithm
 */
export const shuffle = <T>(array: T[]): T[] => {
    const shuffledArray = [...array];

    let remaining = array.length;

    while (remaining) {
        const currentIndex = remaining - 1;
        const randomIndex = crypto.getRandomValues(new Uint32Array(1))[0] % remaining;

        [
            shuffledArray[currentIndex],
            shuffledArray[randomIndex],
        ] = [
            shuffledArray[randomIndex],
            shuffledArray[currentIndex],
        ];

        remaining--;
    }

    return shuffledArray;
};
