// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

import Firehose from 'aws-sdk/clients/firehose';
import isURL from 'validator/lib/isURL';

const getPageUrl = ({ pathname, search }) => {
    const pageUrlParam = pathname.match(/^\/smb\/account\/switch\//i) ? 'returnUrl' : 'pageUrl';
    const re = new RegExp(`${pageUrlParam}=([^&]*)`);
    const result = re.exec(search);
    if (result && result[1]) {
        try {
            return decodeURIComponent(result[1]);
        } catch {
        }
    }
    return null;
};

const link = document.createElement('a');
const filterPleskUrl = url => {
    link.href = url;

    const { pathname, search, hash } = link;

    const pageUrl = getPageUrl(link);
    if (pageUrl) {
        return filterPleskUrl(pageUrl);
    }

    const sensitiveUrls = [
        /^\/smb\/file-manager\//i,
        /^\/(admin|smb)\/backup\//i,
        /\/hosting\/web-directories\//i,
    ];

    if (sensitiveUrls.some(pattern => pathname.match(pattern))) {
        return pathname;
    }

    const sensitiveParams = [
        'searchText',
        'sql_query',
        'user',
        'login',
        'pass',
        'token',
        'key',
        'mail',
    ];

    const safeSearch = search
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, pair) => {
            if (!pair.includes('=')) {
                return [...acc, pair];
            }

            const [key] = pair.split('=');
            if (sensitiveParams.some(param => key.toLocaleLowerCase().includes(param.toLowerCase()))) {
                return [...acc, `${key}=*****`];
            }
            return [...acc, pair];
        }, [])
        .join('&');

    return `${pathname}${safeSearch ? `?${safeSearch}` : ''}${hash}`;
};

const filterExternalUrl = url => {
    const allowedUrls = [
        /^https?:\/\/([\w-]+\.)*plesk\.com/i,
        /^https?:\/\/(www\.)?facebook\.com\/(groups\/)?plesk$/i,
        /^https?:\/\/(www\.)?twitter\.com\/plesk$/i,
    ];

    if (allowedUrls.some(pattern => url.match(pattern))) {
        return url.split('?')[0];
    }
    return null;
};

export const getUrl = href => {
    // eslint-disable-next-line camelcase
    if (href && !isURL(href, { protocols: ['http', 'https'], require_host: false })) {
        return null;
    }

    if (href && href.match(/^(?:[a-z]+:)?\/\//i)) {
        return filterExternalUrl(href);
    }

    return filterPleskUrl(href ? href : window.location.href);
};

const getElementCSSSelector = el => {
    if (!el || !el.localName) {
        return null;
    }
    let label = el.localName.toLowerCase();
    if (el.id) {
        label += `#${el.id}`;
    }
    if (el.classList) {
        for (let i = 0, len = el.classList.length; i < len; ++i) {
            label += `.${el.classList[i]}`;
        }
    }
    return label;
};

const getElementCSSPath = (el, depth) => {
    const paths = [];
    for (let i = 0; el && el.nodeType === Node.ELEMENT_NODE && i < depth; el = el.parentNode, i++) {
        paths.splice(0, 0, getElementCSSSelector(el));
    }
    return paths.length ? paths.join(' ') : null;
};

const getElement = event => {
    let { target } = event;
    const { currentTarget, type } = event;

    if (currentTarget
        && currentTarget.tagName
        && (type === 'load'
            || type === 'error'
            || (type === 'click'
                && currentTarget.tagName.toLowerCase() === 'input'
                && currentTarget.type === 'radio'
            )
        )
    ) {
        target = currentTarget;
    }

    return target.nodeType === Node.TEXT_NODE ? target.parentNode : target;
};

const findElement = (event, selector) => {
    let element = getElement(event);

    if (!selector) {
        return element;
    }

    while (element) {
        if (element.nodeType === Node.ELEMENT_NODE && element.matches(selector)) {
            return element;
        }
        element = element.parentNode;
    }

    return null;
};

export const prepareNodeData = (el, textEl, config) => {
    const data = {};
    const PARENT_DEPTH_LIMIT = 5;
    data.css = getElementCSSPath(el, PARENT_DEPTH_LIMIT);
    if (el.id) {
        data.id = el.id;
    }
    if (config && config.attributes) {
        config.attributes.forEach(attr => {
            if (!el.hasAttribute(attr)) {
                return;
            }
            const value = (attr === 'href') ? getUrl(el.getAttribute(attr)) : el.getAttribute(attr);
            if (value) {
                data[attr] = value;
            }
        });
    }
    ['id', 'type', 'action', ...[config && config.dataset ? config.dataset : []]].forEach(param => {
        if (!(param in el.dataset)) {
            return;
        }
        if (!('dataset' in data)) {
            data.dataset = {};
        }
        data.dataset[param] = el.dataset[param];
    });
    return data;
};

const getParents = target => {
    if (!target || !target.parentElement) {
        return [];
    }

    let parent = target;
    const parents = [];
    while (parent) {
        parents.push(parent.dataset.type);
        parent = parent.parentElement.closest('[data-type]');
    }

    if (parents.length > 1) {
        return parents.slice(1);
    }

    return [];
};

const preparePostData = (action, target) => {
    const data = {};

    if (action.post && target) {
        if (action.post.self) {
            action.post.self.forEach(function (attr) {
                if (attr === 'value') {
                    return;
                }
                const value = target.getAttribute(attr);
                if (value) {
                    data[attr] = value;
                }
            });
        }
        if (action.post.selfText) {
            data.text = target.innerText;
        }
    }

    if (action.data) {
        Object.keys(action.data).forEach(function (key) {
            data[key] = action.data[key];
        });
    }

    const parents = getParents(target);
    if (parents.length > 0) {
        data.parents = parents;
    }

    return data;
};

const handleAnonymousSession = (action, config, data) => {
    if (!config.sessionId) {
        if (!window.localStorage.getItem('uat-aid')) {
            window.localStorage.setItem('uat-aid', Math.random().toString(36).slice(2));
        }
        data['uat-aid'] = window.localStorage.getItem('uat-aid');
        return;
    }
    if ('LOGIN' === action.name && window.localStorage.getItem('uat-aid')) {
        data['uat-aid'] = window.localStorage.getItem('uat-aid');
        window.localStorage.removeItem('uat-aid');
    }
};

const encodeSensitiveChars = list => {
    const preparedChars = {
        '|': encodeURIComponent('|'),
        '\n': encodeURIComponent('\n'),
    };

    const escapeChar = value => {
        if (!value) {
            return '';
        }

        Object.entries(preparedChars).forEach(([raw, encoded]) => {
            value = value.replace(new RegExp(`\\${raw}`, 'g'), encoded);
        });

        return value;
    };

    return list.map(escapeChar);
};

/**
 * Firehose instance
 */
let firehose;
let config;
let initialized = false;
let patches = {};

export const request = (action, target, result) => {
    const parameters = {
        timestamp: (new Date()).toISOString(),
        instanceId: config.instanceId,
        accountLevel: config.accountLevel,
        accountId: config.accountId,
        sessionId: config.sessionId,
        path: action.url || getUrl(),
        action: action.name || null,
        result: result || null,
    };

    const data = preparePostData(action, target);
    handleAnonymousSession(action, config, data);
    if (config.parentId) {
        data.parentId = config.parentId;
    }
    if (Object.keys(data).length) {
        parameters.additionalData = JSON.stringify(data);
    }

    if (typeof config.logger === 'function') {
        config.logger(parameters);
    }
    if (!config.firehose) {
        return;
    }
    if (!firehose) {
        firehose = new Firehose(config.firehose);
    }

    firehose.putRecord({
        DeliveryStreamName: config.sessionId ? config.firehose.stream : config.firehose.noSessionStream,
        Record: {
            Data: `${encodeSensitiveChars(Object.values(parameters)).join('|')}\n`,
        },
    }, () => {
        // empty callback
    });
};

let watchers = {
    contentLoad(contentConfig, expect, action) {
        if (document.readyState === 'loading') {
            window.addEventListener('load', function (event) {
                action.data = action.data || {};
                if (config.extensions) {
                    action.data.extensions = config.extensions;
                }

                setTimeout(() => {
                    if (window.performance) {
                        const perfData = window.performance.timing;
                        action.data.pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
                        action.data.requestTime = perfData.responseEnd - perfData.requestStart;
                        action.data.renderTime = perfData.domComplete - perfData.domLoading;
                    }

                    request(action, event.target);
                }, 0);
            });
        } else {
            request(action, document);
        }
    },

    historyReplaceState(contentConfig, expect, action) {
        window.history.replaceState = new Proxy(window.history.replaceState, {
            apply: (target, thisArg, argArray) => {
                action.data = action.data || {};
                if (config.extensions) {
                    action.data.extensions = config.extensions;
                }

                request(action, document);

                return target.apply(thisArg, argArray);
            },
        });
    },

    click: ({ elements }, expect, action, eventName) => {
        document.addEventListener(eventName, function (event) {
            if (event.uatHandled) {
                return;
            }
            for (let i = 0; i < elements.length; i++) {
                let el;
                let { selector } = elements[i];
                if (selector) {
                    selector = Array.isArray(selector) ? selector : [selector];
                    for (let j = 0; j < selector.length && !el; j++) {
                        el = findElement(event, selector[j]);
                    }
                }
                if (el) {
                    event.uatHandled = true;
                    request({
                        ...action,
                        name: (el.dataset.action || action.name).toUpperCase(),
                        data: prepareNodeData(el, event.target, elements[i]),
                    }, el);
                    break;
                }
            }
        }, true);
    },
};

let actions = [
    {
        expects: [{
            contentLoad: {},
        }],
    },
    {
        expects: [{
            historyReplaceState: {},
        }],
    },
    {
        name: 'CLICK',
        expects: [{
            click: {
                elements: [
                    {
                        selector: '[data-action]',
                    },
                    {
                        selector: 'a',
                        attributes: ['href'],
                    },
                    {
                        selector: 'button',
                    },
                    {
                        selector: [
                            '[class*="commonButton"]', '[class*="btn"]', '[class*="link"]', '[class*="hint"]',
                            '[class*="button"]', '[class*="control"]', '[class*="close"]',
                        ],
                    },
                    {
                        selector: '[role="button"]',
                    },
                ],
            },
        }],
    },
];

const patchUI = () => {
    Object.keys(patches).forEach(name => {
        patches[name]();
    });
};

const startTracking = () => {
    actions.forEach(function (action) {
        action.expects.forEach(function (expect) {
            Object.keys(expect).forEach(function (event) {
                watchers[event] && watchers[event](expect[event], expect, action, event);
            });
        });
    });
};

const UAT = {
    init(initConfig) {
        if (!initConfig || initialized) {
            return;
        }

        config = initConfig;
        patchUI();
        startTracking();
        initialized = true;
    },

    setPatches(fn) {
        patches = fn(patches);
    },

    setActions(fn) {
        actions = fn(actions);
    },

    setWatchers(fn) {
        watchers = fn(watchers);
    },

    setLogger(logger) {
        config.logger = logger;
    },

    dispatchAction(action, data) {
        if (!initialized) {
            return;
        }

        request({ name: action, url: getUrl(), data });
    },

    getConfig() {
        return config;
    },
};

export default UAT;
