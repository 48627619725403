// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import { trafficUsageByCustomerDomainsRoute } from 'routes/trafficUsageByCustomerDomainsRoute';
import TRAFFIC_USAGE_INTERVALS from 'queries/TrafficUsageIntervals.graphql';
import TRAFFIC_USAGE_BY_VIEWER_CLIENTS_LIST from 'queries/TrafficUsageByViewerClientsList.graphql';
import TOTAL_TRAFFIC_USAGE_BY_VIEWER_CLIENTS from 'queries/TotalTrafficUsageByViewerClients.graphql';

export const trafficUsageByViewerCustomersChildrenRoute = ({ path = 'traffic-usage-customers' } = {}) => ({
    path,
    handle: {
        crumb: () => ({
            title: Locale.getSection('components.routes').lmsg('trafficUsageByCustomers'),
        }),
    },
    children: [
        trafficUsageByCustomerDomainsRoute(':customerId/domains'),
    ],
});
export const trafficUsageByViewerCustomersRoute = ({ path = 'traffic-usage-customers', helpContext, ignoreTopLevelTitle = false } = {}) => ({
    path,
    handle: {
        crumb: () => ignoreTopLevelTitle ? [] : ({
            title: Locale.getSection('components.routes').lmsg('trafficUsageByCustomers'),
        }),
        helpContext,
    },
    children: [
        {
            index: true,
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/TrafficUsageByClientsPage" */
                'components/pages/TrafficUsageByClientsPage'
            ),
            loader: async () => {
                const trafficUsageListKey = 'TrafficUsageByViewerCustomersList';
                const clientType = 'client';

                const [{ data: intervalsData }] = await Promise.all([
                    apolloClient.query({
                        query: TRAFFIC_USAGE_INTERVALS,
                    }),
                    apolloClient.query({
                        query: TOTAL_TRAFFIC_USAGE_BY_VIEWER_CLIENTS,
                        variables: { clientType },
                    }),
                    apolloClient.query({
                        query: TRAFFIC_USAGE_BY_VIEWER_CLIENTS_LIST,
                        variables: {
                            input: {
                                ...readGraphQlListInputFromStorage({ key: trafficUsageListKey }),
                                clientTypes: [clientType],
                            },
                        },
                    }),
                ]);

                const { trafficUsageIntervals } = intervalsData.viewer.client.statistics;

                return {
                    trafficUsageIntervals,
                    trafficUsageListKey,
                    clientType,
                    getClientLink: clientId => `${clientId}/domains`,
                };
            },
        },
    ],
});

